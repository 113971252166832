import { render, staticRenderFns } from "./Plan.vue?vue&type=template&id=387150c5&scoped=true&"
import script from "./Plan.vue?vue&type=script&lang=js&"
export * from "./Plan.vue?vue&type=script&lang=js&"
import style1 from "./Plan.vue?vue&type=style&index=1&id=387150c5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "387150c5",
  null
  
)

export default component.exports